const CollectionConfig = {

    tokenName: "BIGDogs Collection",
    tokenSymbol: 'BDNFT',
    baseMetadataURI: "ipfs://bafybeiguknpmuojtchyqb5gyrkhvjbhph5zuhjauw4fyijphhldjv2vpqq/",
    hiddenMetadataUri: 'ipfs://bafybeiguknpmuojtchyqb5gyrkhvjbhph5zuhjauw4fyijphhldjv2vpqq',
    maxSupply: 33,
    whitelistSale: {
        price: 0.05,
        maxMintAmountPerTx: 1,
    },
    preSale: {
        price: 0.07,
        maxMintAmountPerTx: 3,
    },
    publicSale: {
        price: 0.09,
        maxMintAmountPerTx: 5,
    },
    contractAddress: null,
    marketplaceIdentifier: 'BDD',
    whitelistAddresses: [],
};

module.exports = { CollectionConfig };
