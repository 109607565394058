import React from "react";
import "../assets/css/styles.css";
import NavBar from "../components/NavBar";
// import Hero from "../components/Hero";
import Mint from "../components/Mint";
// import Roadmap from "../components/Roadmap";
// import Faq from "../components/Faq";
// import Footer from "../components/Footer";
import Layout from "../components/Layout";
import hero from "../assets/hero/TCNFT.png"


const Home = () => {

    // return (
    //     <div className="home" >
    //         <NavBar />
    //         <Hero />
    //         <Mint />
    //         <Roadmap />
    //         <Faq />
    //         <Footer />
    //     </div>
    // );

    return (
        <Layout pageName={"Time Castles NFT: The first castles on cronos"}>
            <NavBar />
            {/* Hero Header */}
            <div className="neoh_fn_hero">
                {/* Overlay (of hero header) */}
                <div className="bg_overlay">
                    {/* Overlay Color */}
                    <div className="bg_color" />
                    {/* !Overlay Color */}
                    {/* Overlay Image */}
                    <div className="bg_image" data-bg-img={hero} />
                    {/* !Overlay Image */}
                </div>
                {/* Overlay (of hero header) */}
                <div className="hero_content">
                    <div className="container">
                        <div className="content">
                            <h2 className="fn_title" title="Neoh">
                                Time Castles
                            </h2>
                            <p className="fn_desc fn_animated_text">
                                TIME CASTLES NFT(TCNFT) ™ The First Castles on the CRONOS Chain.
                            </p>
                        </div>
                    </div>
            {/*        <a*/}
            {/*            href="#about"*/}
            {/*            className="neoh_fn_down magic-hover magic-hover__square"*/}
            {/*        >*/}
            {/*            <span className="text">Enter The Kingdom</span>*/}
            {/*            <span className="icon">*/}
            {/*  <img src="svg/right-arr.svg" alt="" className="fn__svg" />*/}
            {/*</span>*/}
            {/*        </a>*/}
                </div>
            </div>
              <Mint />
            {/*      <Roadmap />*/}
            {/*     <Faq />*/}
            {/*<Footer />*/}
        </Layout>

    )



};

export default Home;
