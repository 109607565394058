import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import { combineReducers } from 'redux'
import reportWebVitals from './reportWebVitals';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import blockchainReducer from "./features/blockchain";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#1e242f',
//     },
//     secondary: {
//       main: '#1519cb',
//     },
//   },
// });


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  blockchain: blockchainReducer
})


const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

let persistor = persistStore(store)


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    // <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>

            <App />

        </PersistGate>
      </Provider>
    // </React.StrictMode>
);

// ReactDOM.render(
// ,
//   document.getElementById('root')
// );

reportWebVitals();
