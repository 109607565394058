import React from 'react';
import Account from "./Account";
import { Navbar, Container, Nav } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.css';


function NavBar() {
    return (
        <>
            <Navbar bg="" variant="dark" expand="lg" style={{ zIndex: '200' }}>
                <Container>
                    <Navbar.Brand href="/" style={{ fontFamily: "sans serif", color: "#FFF", fontWeight: "700", fontSize: "26px" }}>


                    </Navbar.Brand>
                        <Nav activeKey={window.location.pathname}
                            className="me-auto"
                            style={{ maxHeight: '100px' }}
                            navbarScroll>
                        </Nav>
                        <Account />
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar;
