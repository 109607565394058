import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { Button } from '@mui/material';
import { CircularProgress } from "@mui/material"

import nftContract from "../artifacts/NftContract.sol/NFTNormal.json";
import { contractAddress, networkDeployedTo } from "../utils/contracts-config";
import networksMap from "../utils/networksMap.json";
import CountDown from "./CountDown";
import hero from "../assets/hero/TCNFT.png";

const countdownPeriods = {
    drop: new Date("April 24,2022 ").getTime(),
    whitelisting: new Date("April 28,2022 ").getTime(),
    presale: new Date("July 09,2022 ").getTime(),
}

function Mint() {
    const data = useSelector((state) => state.blockchain.value)
    const _tokenJson = [];


    const [mintingState, setMintingState] = useState({
        userNftsCount: 0,
        currentSupply: 0,
        maxSupply: 0,
        maxMintAmountPerTx: 1,
        mintCost: 0,
        paused: true,
        whitelisting: false,
        presale: false,
        publicSale: false,
        ownerNfts:[],
        tokenJson:[]
    })
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(1)
    const [tokenJson, setTokenJson] = useState([])

    const mintNewNft = async () => {
        if (data.network === networksMap[networkDeployedTo]) {
            try {
                setLoading(true)
                const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
                const signer = provider.getSigner()
                const nft_contract = new ethers.Contract(contractAddress, nftContract.abi, signer);

                const totalMintCost = mintingState.mintCost * amount
                const costInEth = ethers.utils.parseEther(totalMintCost.toString(), "ether")
                const mint_tx = await nft_contract.mint(amount, { value: costInEth })
                await mint_tx.wait()

                setLoading(false)
                await getMintingState()
                setAmount(1)
            } catch (error) {
                setLoading(false)
                window.alert("An error has occured while minting, Try Again")
                console.log(error)
            }
        }
    }



    const getMintingState = async () => {
        if (data.network === networksMap[networkDeployedTo]) {
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            const signer = provider.getSigner()
            const nft_contract = new ethers.Contract(contractAddress, nftContract.abi, provider);

            const currentSupply = await nft_contract.totalSupply()
            const maxSupply = await nft_contract.maxSupply()
            const maxMintAmountPerTx = await nft_contract.maxMintAmountPerTx()
            const paused = await nft_contract.paused()
            const revealed = await nft_contract.revealed()
            const whitelisting = await nft_contract.whitelistMintEnabled()
            const cost = await nft_contract.cost()
            const mintCost = Number(ethers.utils.formatUnits(cost, "ether"))
            const _userNftsCount = await nft_contract.balanceOf(await signer.getAddress())
            const  ownerNftIds = await nft_contract.walletOfOwner(await signer.getAddress())



            setMintingState({
                userNftsCount: Number(_userNftsCount),
                currentSupply: Number(currentSupply),
                maxSupply: Number(maxSupply),
                maxMintAmountPerTx: Number(maxMintAmountPerTx),
                mintCost: mintCost,
                paused: paused,
                whitelisting: whitelisting,
                presale: ((!revealed && !whitelisting) && !paused),
                publicSale: revealed,
                ownerNfts: ownerNftIds,
                tokenJson: tokenJson
            })







        }
    }

    const handleIncrement = () => {
        setAmount(amount + 1)

    }
    const handleDecrement = () => {
        setAmount(amount - 1)
    }


    useEffect(() => {
        getMintingState();
            async function fetchData() {
                let nft = await Promise.all(mintingState.ownerNfts.map(async (nft) => {
                    const res = await (await fetch(`https://ipfs.io/ipfs/bafybeihr4ncdirajxkpdmpfxctifs75hjfzddmbseszw7xwbxkcgimyfyq/${nft}.json`))
                        .json()
                        .then(res => {
                            mintingState.tokenJson.push(res)
                        })
                        .catch(error => console.log("error", error));
                }))
            }
            fetchData();
    },[mintingState.tokenJson])

    return (
        <div className="bg_overlay">
        <div className="mint-section">
            <h1></h1>
            <div className="mint-container">
                {mintingState.paused ? (
                    <>
                        <p>Nft Drop will start in : </p>
                        <CountDown date={countdownPeriods.whitelisting} />
                    </>

                ) : mintingState.whitelisting ? (
                    <>
                        <p>NFT Whitelisting Period Ends in : </p>
                        <CountDown date={countdownPeriods.whitelisting} />
                    </>

                ) : mintingState.presale ? (
                    <>
                        <p>NFT Presale Period Ends in : </p>
                        <CountDown date={countdownPeriods.whitelisting} />
                    </>
                ) : (
                    <p>Public minting is open now : </p>
                )}

                {data.network !== networksMap[networkDeployedTo] ? (
                    <p>Please switch to the {networksMap[networkDeployedTo]} network</p>
                ) : (
                    data.account !== "" ? (
                        <>
                            <p>
                                {mintingState.currentSupply}
                                <span style={{ color: "#f82167", fontWeight: 600 }}> / </span>
                                {mintingState.maxSupply}
                            </p>
                            <div className="d-flex justify-content-center">
                                <button type="button"
                                    className="minus  btn rounded-circle bt-linear"
                                    disabled={amount === 1}
                                    onClick={handleDecrement}>-</button>
                                <input type="number" className="mintnum text-center" readOnly value={amount} />
                                <button type="button"
                                    className="plus btn rounded-circle bt-linear"
                                    disabled={amount === mintingState.maxMintAmountPerTx}
                                    onClick={handleIncrement}>+</button>
                            </div>

                            <Button className='bt-linear'
                                style={{ margin: "10px" }}
                                variant="contained"
                                color="primary"
                                onClick={mintNewNft}>
                                {loading ? <CircularProgress color="inherit" /> : "Mint"}
                            </Button>
                            <br />
                            <p>You are the owner of {mintingState.userNftsCount} TCNFT(s) </p>
                            <div style={{
                                display:"flex",
                                flexWrap: 'wrap',
                                justifyContent:'space-around',
                                padding:'20px',
                                }}>
                                    {
                                        mintingState.tokenJson.length > 0 ?
                                            mintingState.tokenJson.map((nft,i) => (
                                                <div style={{marginBottom:'1.5rem'}}  key={nft.edition}>
                                                    <img style={{verticalAlign: 'left',width:'80%'}} src={`https://ipfs.io/ipfs/bafybeiguknpmuojtchyqb5gyrkhvjbhph5zuhjauw4fyijphhldjv2vpqq/${nft.edition}.png`}/>
                                                    <div style={{fontSize:'12px'}}>{nft.name}</div><br/>
                                                <div style={{fontSize:'12px', textAlign:'left'}}>{nft.description}</div><br/>
                                                  <li style={{fontSize:'12px', textAlign:'left'}}>{nft.attributes[0].trait_type} - {nft.attributes[0].value}</li>
                                                  <li style={{fontSize:'12px', textAlign:'left'}}>{nft.attributes[1].trait_type} - {nft.attributes[1].value}</li>
                                                  <li style={{fontSize:'12px', textAlign:'left'}}>{nft.attributes[2].trait_type} - {nft.attributes[2].value}</li>
                                                  <li style={{fontSize:'12px', textAlign:'left'}}>{nft.attributes[3].trait_type} - {nft.attributes[3].value}</li>
                                                  <li style={{fontSize:'12px', textAlign:'left'}}>{nft.attributes[4].trait_type} - {nft.attributes[4].value}</li>
                                                  <li style={{fontSize:'12px', textAlign:'left'}}>{nft.attributes[5].trait_type} - {nft.attributes[5].value}</li>
                                                  <li style={{fontSize:'12px', textAlign:'left'}}>{nft.attributes[6].trait_type} - {nft.attributes[6].value}</li>
                                                </div>

                                            ))
                                            : ""
                                    }
                </div>
                        </>
                    ) : (
                        <>
                            <p>
                                {mintingState.currentSupply}
                                <span style={{ color: "#f82167", fontWeight: 600 }}> / </span>
                                {mintingState.maxSupply}
                            </p>
                            <br />
                            <p>You must be connected to mint</p>
                        </>
                    )
                )}
            </div>
        </div >
        </div>
    )
}

export default Mint;
